/* eslint-disable */
import React from 'react'

import DentistSmiling from '../../images/people/dentist-smiling.png'
import DentistSmilingMobile from '../../images/people/dentist-smiling-mobile.png'
import CoupleSearchingInternet from '../../images/people/couple-searching-internet.jpg'
import CoupleSearchingInternetMobile from '../../images/people/couple-searching-internet-mobile.jpg'
import WomanSmilingDentist from '../../images/people/woman-smiling-dentist.jpg'
import WomanSmilingDentistMobile from '../../images/people/woman-smiling-dentist-mobile.jpg'
import vibrantDarkBlueBackground from '../../images/backgrounds/vibrant-dark-blue.jpg'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import humana from '../../images/brand-logos/humana.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import united from '../../images/brand-logos/united-healthcare.svg'

import NarrowBanner from '../../components/NarrowBanner'

import {
  Billboard,
  Carousel,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Find an in-network dentist near you | Clearlinkinsurance.com',
          description: 'Find and book an in-network dentist near you.',
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/find/find-a-dentist',
        promoCode: '169960',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Find an in-network dentist near you | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-split-content">
          <div className="wrapper">
            <Billboard
              backgroundColor="white"
              alignImageToBottom
              variant="split"
              image={
                <img
                  src={DentistSmiling}
                  alt="a dentist folds his arms and smiles"
                />
              }
              mobileImage={
                <img
                  src={DentistSmilingMobile}
                  alt="a dentist folds his arms and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Medicare: Find a dentist in-network near you
                  </Typography>
                  <Typography variant="h5">
                    Clearlink Insurance Agency makes it easy for clients to find
                    plan-covered dental care.
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to="#find-a-dentist"
                    color="pink"
                  >
                    Find a Dentist
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <div className="hide-nav-button" id="find-a-dentist">
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">Find a dentist</Typography>
                <Typography variant="body">
                  Click or tap the button associated with your dental provider
                  to visit their dentist search page. From there you can find
                  in-network dental coverage in your area.
                </Typography>
              </>
            }
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={aetna}
                    alt="Aetna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.aetnamedicare.com/en/find-doctors-hospitals/find-provider.html"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={cigna}
                    alt="Cigna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://hcpdirectory.cigna.com/web/public/consumer/directory/search?consumerCode=HDC056"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={humana}
                    alt="Humana logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://finder.humana.com/finder/medical?customerId=1"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={united}
                    alt="United Healthcare logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://connect.werally.com/plans/uhc"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={wellcare}
                    alt="Wellcare logo"
                    style={{ maxWidth: '90px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.wellcare.com/en/fap"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={CoupleSearchingInternet}
              alt="a couple sits on couch together and looks at laptop"
            />
          }
          mobileImage={
            <img
              src={CoupleSearchingInternetMobile}
              alt="a couple sits on couch together and looks at laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">How to search for a dentist</Typography>
              <Typography variant="body">
                When you click or tap on the button associated with your dental
                provider above, you’ll be taken to your insurance company’s
                “Find a Dentist” page.
              </Typography>
              <Typography variant="body">
                From there, you can log in if you already have an account, or
                continue as a guest, to search for a dentist near you that
                accepts your plan coverage.
              </Typography>
              <Typography variant="body">
                To make sure you find the right dentist for you, we recommend
                having the following information at the ready:
              </Typography>
              <List>
                <ListItem>Your plan ID card</ListItem>
                <ListItem>Plan year</ListItem>
                <ListItem>Plan type</ListItem>
                <ListItem>ZIP code</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          style={{
            backgroundImage: `url(${vibrantDarkBlueBackground})`,
            color: '#fff',
          }}
          alignMainContent="Center"
          mainContent={
            <>
              <Typography variant="h1">
                Find specialized medical providers
              </Typography>
              <Typography variant="body">
                Not looking for a dentist? Use the button below to return to our
                medicare provider search hub. There, you can search for doctors,
                dentists, and other providers covered by your plan.
              </Typography>
              <LinkButton variant="" to="/find" color="pink">
                Find a healthcare provider
              </LinkButton>
            </>
          }
        ></VariableContent>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={WomanSmilingDentist}
              alt="a woman smiles after a visit to the dentist's office"
            />
          }
          mobileImage={
            <img
              src={WomanSmilingDentistMobile}
              alt="a woman smiles after a visit to the dentist's office"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Tips for finding the right dentist for you
              </Typography>
              <Typography variant="body">
                Maintaining dental health is important, but visiting the dentist
                can be stressful, especially if you haven’t been to the dentist
                in a long time or are seeing a new dentist for the first time.
              </Typography>
              <Typography variant="body">
                To ensure you choose the right dentist for you, we recommend a
                few of these tips:
              </Typography>
              <List>
                <ListItem>
                  Visit the office in person before making an appointment to get
                  comfortable in your surroundings. Some dentists even offer
                  pre-appointment meetings or consultations—take advantage of
                  this option when available.
                </ListItem>
                <ListItem>
                  Ask about sedation options. Some dentists offer more extensive
                  sedation options than others for potentially painful
                  procedures.
                </ListItem>
                <ListItem>
                  Read online reviews. You can find these easily by inputting
                  the dentist’s name and location in the search engine of your
                  choice.
                </ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Robert A. from Texas says
                  </Typography>
                  <Typography variant="body">
                    “...definitely went above and beyond what could be expected
                    on trying to clear up a messy situation with an insurance
                    company. When it looked like all was futile, you were able
                    to come through and provide us with the coverage we were
                    looking for.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Joseph A. from the U.S. says
                  </Typography>
                  <Typography variant="body">
                    “The agent was informative and helpful. A good experience.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
